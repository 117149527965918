import { useState } from "react";
import OverflowTooltip from "../OverflowTooltip";

type ToggleComponentProps = {
  renderItem: any;
  toggleObject?: any;
};

// Иногда необходимо указывать макс ширину для родительского элем

const ToggleComponent: React.FC<ToggleComponentProps> = ({
  renderItem,
  toggleObject,
}) => {
  const [toggle, setToggle] = useState(true);

  const handleToggle = () => {
    setToggle((prev) => !prev);
  };

  return (
    <OverflowTooltip title={toggle ? "Раскрыть" : "Скрыть"} placement="bottom">
      {renderItem(toggle, handleToggle, toggleObject)}
    </OverflowTooltip>
  );
};

export default ToggleComponent;
