import { styled, useTheme } from "@mui/material";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  DateTimePicker,
  DateTimePickerProps,
} from "@mui/x-date-pickers/DateTimePicker";
import ru from "date-fns/locale/ru/index.js";
import {
  DateTimeValidationError,
  PickerChangeHandlerContext,
} from "@mui/x-date-pickers";

import { ruRU } from "@mui/x-date-pickers/locales";
import { ChevronDownIcon } from "../../assets/IconsSVG";
import { useIsMobile } from "@utils/hooks/useIsMobile";

const russianLocale =
  ruRU.components.MuiLocalizationProvider.defaultProps.localeText;

export type CalendarProps = {
  handler: (
    value: Date | null,
    context: PickerChangeHandlerContext<DateTimeValidationError>
  ) => void;
  required?: boolean;
  tooltip?: string;
  mode?: "light" | "dark";
  [x: string]: any;
} & DateTimePickerProps<any>;

const Calendar = styled(
  ({
    required,
    handler,
    tooltip,
    error,
    helperText,
    mode = "light",
    ...props
  }: CalendarProps) => {
    const isMobile = useIsMobile();
    const theme = useTheme();

    return (
      <>
        <LocalizationProvider
          localeText={russianLocale}
          adapterLocale={ru}
          dateAdapter={AdapterDateFns}
        >
          <DateTimePicker
            sx={{
              width: "100%",
            }}
            views={["day", "month", "year"]}
            format="dd.MM.yyyy"
            // format="dd.MM.yyyy.hh.mm.ss"
            onChange={handler}
            slots={{
              openPickerIcon: ChevronDownIcon,
            }}
            slotProps={{
              textField: {
                helperText,
                error,
                required: required,
              },
              openPickerButton: {
                title: tooltip,
              },
              openPickerIcon: {
                mode: theme.palette.mode,
              },
              actionBar: {
                sx: {
                  display: isMobile ? "inline-block" : "none",
                },
              },
            }}
            {...props}
          />
        </LocalizationProvider>
      </>
    );
  }
)(({ theme }) => ({
  "& .MuiInputBase-root": {
    border: "1px solid #93A0B1",
    overflow: "hidden",
  },
}));

export default Calendar;
