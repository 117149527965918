import { Theme } from "@mui/material/styles";

const Typography = (theme: Theme) => {
  return {
    h1: {
      fontWeight: "700",
      [theme.breakpoints.only("xl")]: {
        fontSize: "2.8rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "2.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "2.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.6rem",
      },
    },

    h2: {
      fontWeight: "600",
      [theme.breakpoints.only("xl")]: {
        fontSize: "2.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "2.3rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.6rem",
      },
    },

    h3: {
      fontWeight: "400",
      [theme.breakpoints.only("xl")]: {
        fontSize: "2.2rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "2.2rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.8rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.6rem",
      },
    },

    h4: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.5rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.1rem",
      },
    },

    body1: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1rem",
      },
    },

    body2: {
      fontWeight: "300",
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1rem",
      },
    },

    body3: {
      fontWeight: 600,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.6rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.4rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.3rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1.2rem",
      },
    },

    body4: {
      fontWeight: 400,
      color: theme.palette.text.primary,
      [theme.breakpoints.only("xl")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("lg")]: {
        fontSize: "1.2rem",
      },
      [theme.breakpoints.only("md")]: {
        fontSize: "1.1rem",
      },
      [theme.breakpoints.only("sm")]: {
        fontSize: "1rem",
      },
      [theme.breakpoints.only("xs")]: {
        fontSize: "1rem",
      },
    },
  };
};

export default Typography;
