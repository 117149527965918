import { Box, MenuItem, TextField, TextFieldProps } from "@mui/material";

import { styled, useTheme } from "@mui/material/styles";

import { ChevronRightIcon } from "../../../assets/IconsSVG";

type PaginationSelectProps = {
  currentPageSize: number;
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
} & TextFieldProps;

const PaginationSelect = styled(
  ({ currentPageSize, onChange, ...props }: PaginationSelectProps) => {
    const countArray = [5, 10, 15, 20, 30, 50];
    const theme = useTheme();

    return (
      <Box>
        <TextField
          select
          variant="outlined"
          fullWidth
          value={currentPageSize}
          onChange={onChange}
          SelectProps={{
            displayEmpty: true,
            MenuProps: {
              disableAutoFocusItem: true,
            },

            IconComponent: (props) => {
              return (
                <ChevronRightIcon
                  color={theme.palette.pagination.item.iconColor}
                  className={props.className}
                />
              );
            },
          }}
          {...props}
        >
          {countArray.map((count, idx) => (
            <MenuItem value={count} key={idx}>
              {count}
            </MenuItem>
          ))}
        </TextField>
      </Box>
    );
  }
)(({ theme }) => ({
  background: "transparent",
  border: 0,

  "& .MuiOutlinedInput-root": {
    borderRadius: "6px",
    border: `1px solid ${"#BDCADB"}`,
    width: "10.3rem",
    height: "3rem",
    display: "flex",
    alignItems: "center",
    background: "none",
  },

  "& .MuiSelect-select": {
    color: theme.palette.pagination.select.textColor,
  },

  "& .MuiSelect-icon": {
    top: "calc(50% - 0.4em)",
    right: "1rem",
  },
}));

export default PaginationSelect;
