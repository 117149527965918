import { Box, BoxProps } from "@mui/material";

import { styled } from "@mui/material/styles";

export const StyleLogoContainer = styled((props: BoxProps) => (
  <Box {...props} />
))(({ theme }) => ({
  width: "4.7rem",
  height: "7rem",
  marginBottom: "5.5rem",
  margin: "0 0 5.5rem 2.2rem",
}));
