import { useHub } from "@utils/hooks/useHub";
import { useAuth } from "@utils/hooks/useAuth";

import SideBar from "@containers/Sidebar";
import { Toast } from "@components";

import { useTheme, Drawer, Box } from "@mui/material";

const drawerWidth = 298;

type RootLayoutProps = {
  window?: () => Window;
  children: React.ReactNode;
};

const RootLayout: React.FC<RootLayoutProps> = ({ window, children }) => {
  const { role } = useAuth();
  useHub(role);
  const theme = useTheme();
  const drawer = <SideBar />;
  return (
    <Box
      sx={{
        display: "flex",
        height: "100%",
      }}
    >
      <Toast />

      <Box
        component="nav"
        sx={{
          width: {
            xs: 0,
            sm: 0,
            md: drawerWidth,
            lg: drawerWidth,
          },
        }}
      >
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: "none", sm: "none", md: "block", lg: "block" },
            "& .MuiDrawer-paper": {
              boxSizing: "border-box",
              width: drawerWidth,
              borderRadius: "0 1.5rem 1.5rem 0",
              color: "#566478",
              padding: "2rem 0 5.5rem 0",
              border: 0,
              background: theme.palette.sidebar.bg,
            },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          p: 1,
          width: {
            xs: "100%",
            sm: "100%",
            md: `calc(100% - ${drawerWidth}px)`,
            lg: `calc(100% - ${drawerWidth}px)`,
          },

          height: "100%",
          position: "relative",
          display: "flex",
          flexDirection: "column",
          padding: 0,
        }}
      >
        {children}
      </Box>
    </Box>
  );
};

export default RootLayout;
