import { useAppSelector } from "@utils/hooks/redux-hooks";
import { useFormik } from "formik";
import { useContext } from "react";

import * as yup from "yup";

import Typography from "@mui/material/Typography";

import Box from "@mui/material/Box";
import { Alert, Button, useTheme } from "@mui/material";

import { Toast, Spinner } from "@components";

import { useDocumentTitle } from "@utils/hooks/useDocumentTitle";
import { TokenInMemoryContext } from "@services/auth/TokenInMemoryContext";
import { selectProfileLoadingStatus } from "@redux/slices/profile/selectors";
import useKeyLock from "@utils/hooks/useKeyLock";
import { emailRegex } from "@utils/validation/validateEmail";

import PersonIcon from "@assets/login/PersonIcon";
import LoginLogo from "@assets/login/LoginLogo";

import {
  StyledContainer,
  StyledSignInButtons,
  StyledSignInContainer,
  StyledSignInHeader,
  StyledSignInInputs,
  StyledLoginTextField,
  StyledPasswordTextField,
} from "./style";

import { CapslockIcon } from "@assets/IconsSVG";

const Login = () => {
  const theme = useTheme();
  const { loginHandler } = useContext(TokenInMemoryContext);
  const loadingStatus = useAppSelector(selectProfileLoadingStatus);
  useDocumentTitle("Авторизация");
  const capsLock = useKeyLock("CapsLock");

  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },

    validationSchema: yup.object({
      email: yup
        .string()
        .email("Некорректная почта")
        .trim()
        .matches(emailRegex, "Некорректная почта")
        .required("Имя обязательное поле"),
      password: yup.string().required("Пароль обязательное поле"),
    }),

    onSubmit: (values) => {
      const { email, password } = values;
      loginHandler(email, password);
    },
  });

  return (
    <Box sx={{ width: "100%", height: "100%" }}>
      {loadingStatus ? (
        <Spinner />
      ) : (
        <StyledContainer>
          <StyledSignInContainer onSubmit={formik.handleSubmit}>
            <Toast />
            <StyledSignInHeader>
              <LoginLogo mode={theme.palette.mode} />
              <Typography textAlign="center" component="h2" variant="h2">
                Авторизация на сервисах экосистемы - КАМАЗ
              </Typography>
            </StyledSignInHeader>

            <StyledSignInInputs>
              <StyledLoginTextField
                fullWidth
                required
                label="Имя пользователя"
                id="email"
                name="email"
                onChange={formik.handleChange}
                value={formik.values.email}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
                InputProps={{
                  endAdornment: (
                    <PersonIcon
                      color={
                        theme.palette.mode === "light" ? "#000" : "#4A6084"
                      }
                    />
                  ),
                }}
              />

              <StyledPasswordTextField
                required
                iconColor={theme.palette.mode === "light" ? "#000" : "#4A6084"}
                autoComplete="new-password"
                fullWidth
                label="Пароль"
                id="password"
                name="password"
                onChange={formik.handleChange}
                value={formik.values.password}
                error={
                  formik.touched.password && Boolean(formik.errors.password)
                }
                helperText={formik.touched.password && formik.errors.password}
              />
            </StyledSignInInputs>

            {capsLock && (
              <Alert
                icon={<CapslockIcon />}
                sx={{ mt: 2, mb: 8 }}
                severity="warning"
              >
                Caps Lock включен!
              </Alert>
            )}

            <StyledSignInButtons>
              <Button
                fullWidth
                type="submit"
                color="primary"
                variant="contained"
              >
                Войти
              </Button>
            </StyledSignInButtons>
          </StyledSignInContainer>
        </StyledContainer>
      )}
    </Box>
  );
};

export default Login;
