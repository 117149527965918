import NavLink from "../Navlink";

import FormatListBulletedIcon from "@mui/icons-material/FormatListBulleted";

import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemText from "@mui/material/ListItemText";
import ListItemIcon from "@mui/material/ListItemIcon";

const DispatcherItem = () => {
  return (
    <>
      <List component="div" disablePadding>
        <NavLink to="/waybills">
          {(isActive) => (
            <ListItemButton disableRipple>
              <ListItemIcon>
                <FormatListBulletedIcon />
              </ListItemIcon>
              <ListItemText
                primary="Заявки"
                primaryTypographyProps={{ variant: "body2", fontWeight: "700" }}
              />
            </ListItemButton>
          )}
        </NavLink>
      </List>
    </>
  );
};

export default DispatcherItem;
