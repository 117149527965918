import { Components, Theme } from "@mui/material/styles";

const Typography = (theme: Theme): Components<Theme> => {
  return {
    MuiTypography: {
      defaultProps: {
        variantMapping: {
          body3: "span",
          body4: "span",
        },
      },
    },
  };
};

export default Typography;
