import { TextFieldProps } from "@mui/material";
import StandartTextField from "./StandartTextField";
import { IMaskMixin, IMaskMixinProps } from "react-imask";

export type MaskTextFieldProps = {
  handler: (value: string) => void;
  mask: string;
} & Omit<TextFieldProps, "size" | "ref" | "color"> &
  IMaskMixinProps<HTMLInputElement>;

const MaskedTextField = IMaskMixin(
  ({ inputRef, size, color, as, ...otherProps }) => (
    <StandartTextField {...otherProps} inputRef={inputRef} />
  )
);

const MaskTextField: React.FC<MaskTextFieldProps> = (props) => {
  const { handler, ...rest } = props;

  return (
    <MaskedTextField
      onAccept={(value: string) => {
        handler(value);
      }}
      {...rest}
    />
  );
};

export default MaskTextField;
