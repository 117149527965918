import DropDownList from "./components/DropDownList";

import { useAuth } from "@utils/hooks/useAuth";

import KamazLogo from "@assets/Sidebar/KamazLogoIcon.png";
import { StyleLogoContainer } from "./style";

const SideBar = () => {
  const { role, email } = useAuth();
  return (
    <>
      {role && (
        <>
          <StyleLogoContainer>
            <img src={KamazLogo} alt="logo" />
          </StyleLogoContainer>
          <DropDownList userRole={role} email={email!} />
        </>
      )}
    </>
  );
};

export default SideBar;
